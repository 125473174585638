import React from 'react';
import Img1 from '../assets/images/extra-2/screenshot/mocup.png'
import Img2 from '../assets/images/extra-2/screenshot/mocup-screen.png'
import {BsApple} from 'react-icons/bs'
import {DiAndroid} from 'react-icons/di'
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;

const Section6 = () => {
  return (
    <div>

<section className="apps-download-section overflow-hidden padding-top padding-bottom body--bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">An Exhaustive list of Amazing Features</h5>
                        <h2 className="title">Ready to take your productivity to the next level?</h2>
                        <p className="mx-auto mw-540px">
                            Are you ready to make the leap? Download Apps for iOS or Android today — it's free.
                        </p>
                    </div>

                </div>
            </div>

      

            <div className="apps-download-buttons-20">
                <h6 className="title cl-white text-center mb-4">Ready to get started?</h6>
                <ul className="download-options">
                    <li>
                    <div>
      <Toaster style={{backgroundColor:"red"}} />
    </div>
                <a onClick={notify} className="active" style={{backgroundColor:'black'}}><DiAndroid style={{color:'white'}}/></a>
                        <span className="cl-white">Android</span>
                    </li>
                    <li>
                        <a onClick={notify}  style={{backgroundColor:'black'}}><BsApple style={{color:'white'}}/></a>
                        <span className="cl-white">ios</span>
                    </li>
                </ul>
            </div>
            <div className="apps-download-screen-20 py-lg-1 py-md-5 py-sm-5 py-5">
                <div className="apps-download-bg">
                    <img src={Img1} alt="extra-2/screenshot"/>
                </div>
                <div className="apps-download-thumb text-center">
                    <img src={Img2} alt="extra-2/screenshot"/>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Section6