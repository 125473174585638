import React from 'react'
import Img1 from '../assets/images/banner/banner16/01.png'
import Img2 from '../assets/images/banner/banner16/02.png'

import Img3 from '../assets/images/banner/banner16/03.png'

import Img4 from '../assets/images/banner/banner16/04.png'

import Img5 from '../assets/images/banner/banner16/05.png'


const Section1 = () => {
  return (
    <div>
 <section className="logo-slider-section section--bg py-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 text-center mb-4 mb-lg-5">
                    <h5 className="title">Trusted by the World's Best Team</h5>
                </div>
            </div>
            <div className="logo-slider d-flex justify-content-between homeparterimg">
                <div className="item">
                    <img src={Img1} width="80px" alt="banner"/>
                </div>
                <div className="item">
                    <img src={Img2} width="80px" alt="banner"/>
                </div>
                <div className="item">
                    <img src={Img3} width="100%" alt="banner"/>
                </div>
                <div className="item">
                    <img src={Img4} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Img5} alt="banner"/>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Section1