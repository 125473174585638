import React from 'react'
import Img1 from '../assets/images/extra-2/feature/phone.png'
import Img2 from '../assets/images/extra-2/feature/01.png'

const Section2 = () => {
  return (
    <div>
 <section className="feature-section ovelflow-hidden padding-bottom padding-top">
        <div className="container-fluid p-5">
            <div className="row justify-content-center">
                <div className="col-xl-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">Features</h5>
                        <h2 className="title homefecturetext">Powerful features to Supercharge your productivity</h2>
                        <p className="mx-auto mw-540px">
                            Numerous features make it possible to customize the system in accordance with all your needs.
                        </p>
                    </div>

                </div>
            </div>
            <div className="feature-wrapper-20">
                <div className="feature-wrapper-bg-20 bg_img bg2">
                    <div className="row align-items-center">
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="feature-thumb-20 rtl text-center">
                                <img src={Img1} width="60%" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-content-20 cl-white">
                                <div className="feature-content-slider-20">
                                    <div className="feature-content-item-20">
                                        <div className="feature-content-icon-20">
                                            <img src={Img2}alt="extra-2/feature"/>
                                        </div>
                                        <h3 className="title">
                                        Let you give the notification Alert in your mobile
                                        </h3>
                                        <p>
                                        The notification will providing awareness about the things which going around in your Mantos App.Allow notifcation as per your choice you can see many function which remeber help out you in different scenario
                                        </p>
                                    </div>
                                  
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Section2