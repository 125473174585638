import React from 'react'
import Teamhero from './Teamhero'

const Team = () => {
  return (
    <div>
<Teamhero/>

    </div>
  )
}

export default Team