import React from 'react'
import Featurehero from './Featurehero'

const Feature = () => {
  return (
    <div>
<Featurehero/> 
  </div>
  )
}

export default Feature