import React ,{useState}from 'react'
import {AiOutlineMenu} from 'react-icons/ai'
import {AiOutlineClose} from 'react-icons/ai'
import './Navbar.css';
import Img1 from '../../assets/images/logo/logo.png'
import {Link } from "react-router-dom";


const Navbar = () => {
   const [click,setClick]=useState(false);
   const handleClick=()=>setClick(!click)
  return (
    <div className='navbar'>
        <div className="container">
          
        <Link className='navlink' to="/">      <img src={Img1} width='80px' alt="" style={{margin:0}}/></Link>


        <ul className={click ? 'nav active' :'nav'}>
            <li><Link className='navlink' to="/"> Home </Link></li>
            
            <li><Link className='navlink' to="About"> About </Link></li>
            
            <li><Link className='navlink' to="Appdownload"> App Downoad </Link></li>
            
            <li><Link className='navlink' to="Feature" > Feature </Link></li>
            <li><Link className='navlink' to="Contact"> Contact </Link></li>
            <li><Link className='navlink' to="Privacypolicy"> Privacy Policy </Link></li>
            {/* <li><Link className='navlink' to="Signin" > Signin </Link></li> */}

        </ul>
      

        <div className='humburger' onClick={handleClick}>
          {click ?(<AiOutlineClose className='icon'/>):(<AiOutlineMenu className='icon'/>)}

        </div>

         
       
        </div>
        

        </div>
        
  )
}

export default Navbar