import React from 'react'
import Img1 from '../assets/images/newslater/newslater1.png'
import Img3 from '../assets/images/how/how-much-bg.png'
import { Link } from 'react-router-dom'
import {DiAndroid} from 'react-icons/di'
import {BsApple} from 'react-icons/bs'
import {AiFillStar} from 'react-icons/ai'
import {BiPaperPlane} from 'react-icons/bi'
const Bottom = () => {
  return (
    <div>

<section className="newslater-section oh subscribe-pt">
        <div className="bg_img right_center" data-background="assets/images/bg/newslater.jpg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="rtl subscribe-thumb">
                            <img src={Img1} alt="newslater"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="newslater-area padding-bottom padding-top">
                            <div className="section-header left-style cl-white">
                                <h5 className="cate">Be the first to know</h5>
                                <h2 className="title">About New Features</h2>
                                <p>
                                    If you want to receive monthly updates from us just pop your email in the box. You can unsubscribe at any time. Your privacy & personal information will be treated.
                                </p>
                            </div>
                            <form className="subscribe-form">
                                <input type="text" placeholder="Enter Your Email"/>
                                <button type="submit">subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section className="coverage-section padding-top padding-bottom-2" id="coverage">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="section-header left-style coverage-header">
                        <h5 className="cate">Our stats say more than any words</h5>
                        <h2 className="title">Apps Without Borders</h2>
                        <p>
                            Mantos app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                        </p>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="coverage-right-area text-lg-right">
                        <div className="rating-area">
                            <div className="ratings">
                                <span><AiFillStar style={{color:'orange'}}/></span>
                                <span><AiFillStar style={{color:'orange'}}/></span>
                                <span><AiFillStar style={{color:'orange'}}/></span>
                                <span><AiFillStar style={{color:'orange'}}/></span>
                                <span><AiFillStar style={{color:'orange'}}/></span>
                            </div>
                            <span className="average">5.0 / 5.0</span>
                        </div>
                        <h2 className="amount">312,921+</h2>
                        <a href="#0">Total User Reviews <BiPaperPlane/></a>
                    </div>
                </div>
            </div>
            <div className="coverage-wrapper bg_img bg3">
                <div className="border-item-1">
                    <span className="name">North America</span>
                    <h2 className="title">70.7%</h2>
                </div>
                <div className="border-item-2">
                    <span className="name">Asia</span>
                    <h2 className="title">14.4%</h2>
                </div>
                <div className="border-item-3">
                    <span className="name">North Europe</span>
                    <h2 className="title">8.4%</h2>
                </div>
                <div className="border-item-4">
                    <span className="name">South America</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-5">
                    <span className="name">Africa</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-6">
                    <span className="name">Australia</span>
                    <h2 className="title">3%</h2>
                </div>
            </div>
        </div>
    </section> */}


    {/* <div className="member-counter-section oh bg-max-lg-dark padding-bottom pb-lg-0">
        <div className="container-fluid">
            <div className="member-counter-area padding-bottom padding-top mt-0">
                <div className="member-bg">
                    <img src={Img3} alt="how"/>
                </div>
                <div className="member-counter cl-white">
                    <h2 className="title"><span class="counter">455,326,234</span></h2>
                    <p>People Joined Already</p>
                    <Link to="Signin" class="custom-button">Download App</Link>
                    <ul className="download-options">
                        <li>
                            <a href="#0" class="active"><BsApple/></a>
                        </li>
                        <li>
                            <a href="#0"  class="active"><DiAndroid/></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> */}


  


    </div>
  )
}

export default Bottom