import React from 'react'
import Img1 from '../assets/images/feature/am-fea1.png'
import Img2 from '../assets/images/feature/am-fea2.png'
import Img3 from '../assets/images/feature/am-fea3.png'
import Img4 from '../assets/images/feature/am-fea4.png'
import Img5 from '../assets/images/how/how2.png'
import Img6 from '../assets/images/how/how1.png'
import Img7 from '../assets/images/how/how3.png'
import Img8 from '../assets/images/feature/feature-shape.png'
import Img10 from '../assets/images/feature/pro-main.png'
import Img9 from '../assets/images/feature/pro1.png'
import Img11 from '../assets/images/feature/pro2.png'
import Img12 from '../assets/images/feature/pro3.png'
import Img13 from '../assets/images/feature/pro4.png'
import { Link } from 'react-router-dom'
import { BsApple } from "react-icons/bs";
import {DiAndroid} from 'react-icons/di'

import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;

const Section8 = () => {
  return (
    <div>

<section className="amazing-feature-section pos-rel" id="feature">
       
        <div className=" ">
            <div className="container mt-5">
                <div className="row">
                   
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-sm-6 col-lg-3">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img1} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Signup</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="am-item active">
                                    <div className="am-thumb">
                                        <img src={Img2} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Secure Data</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Quick Access</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img4} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">24h Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="how-section padding-bottom padding-top" id="how">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Describe Your App</h5>
                <h2 className="title">Let’s See How It Work</h2>
                <p>It's easier than you think.Follow 3 simple easy steps</p>
            </div>
            <div className="row justify-content-xl-between justify-content-center mb-40-none">
                <div className="col-lg-4 col-sm-6 col-xl-3">
                    <div className="how-item">
                        <div className="how-thumb">
                            <img src={Img6} alt="how"/>
                        </div>
                        <div className="how-content">
                            <Link to="Appdownload" className="button-3 active">Download App</Link>
                            <ul className="download-options">
                                <li>
                                    <a onClick={notify} className="active"><BsApple/></a>
                                </li>
                                <li>
                                    <a onClick={notify} className='active'><DiAndroid/></a>
                                </li>
                            </ul>
                            <p>Download App either for Mac or Android</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xl-3">
                    <div className="how-item">
                        <div className="how-thumb">
                            <img src={Img5} alt="how"/>
                        </div>
                        <div className="how-content">
                            <Link onClick={notify} className="button-3">Create Account</Link>
                            <h5 className="title">Easy way To Signup</h5>
                            <p>Sign up for Mantos account.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xl-3">
                    <div className="how-item">
                        <div className="how-thumb">
                            <img src={Img7} alt="how"/>
                        </div>
                        <div className="how-content">
                            <a onClick={notify} className="button-3">Enjoy The App</a>
                            <h5 className="title">Have any questions
                                check our <Link to="Faqs">FAQs</Link></h5>
                            <p>Explore and share mosta</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="feature-section padding-top padding-bottom oh pos-rel">
        <div className="feature-shapes d-none d-lg-block">
            <img src={Img8}  alt="feature"/>
        </div>
        <div className="container">
            <div className="section-header mw-725">
                <h5 className="cate">Extra Crazy Features that will</h5>
                <h2 className="title">Boost Your Productivity </h2>
                <p>
                    In the process of making a app, the satisfaction of users is the most important and the focus is on usability and completeness
                </p>
            </div>
            <div className="row">
                <div className="col-lg-5 rtl">
                    <div className="feature--thumb pr-xl-4 ltr">
                        <div className="feat-slider" data-slider-id="1">
                           
                            <div className="main-thumb">
                                <img src={Img10} width="90%" alt="feature"/>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="feature-wrapper mb-30-none owl-thumbs" data-slider-id="1">
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={Img9} alt="feature"/>
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Free Tax Filing</h4>
                                <p>Mantos is allowing you a free tax filling that would safe your money</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={Img11} alt="feature"/>
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Bank Transfers</h4>
                                <p>Now Transfering money through Bank at any time will be very easy through Mantos App</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={Img12} alt="feature"/>
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Direct Deposit</h4>
                                <p>You can Deposit your amount  directly at mantos app through your creadit card easily and securily</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={Img13} alt="feature"/>
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Recurring Deposit</h4>
                                <p>You can check your intantly deposit through clicking on recurring deposit icon</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Section8