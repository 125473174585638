import React ,{useState} from 'react'
import Img3 from '../../assets/images/sponsor/sponsor1.png'
import Img4 from '../../assets/images/sponsor/sponsor2.png'
import Img5 from '../../assets/images/sponsor/sponsor3.png'

import Img6 from '../../assets/images/sponsor/sponsor4.png'

import Img7 from '../../assets/images/sponsor/sponsor5.png'
import Img8 from '../../assets/images/sponsor/sponsor6.png'
import Img9 from '../../assets/images/sponsor/sponsor7.png'
import { Link,useSearchParams,useNavigate} from 'react-router-dom'
import swal from 'sweetalert';

const Loginotp = () => {
const  [searchparams]=useSearchParams()
const [otp,setOtp]=useState("")
const navigate=useNavigate();

async function Login(){
    let phone=searchparams.get("phone");
    let item1={phone,otp}
    console.warn(item1)

 return fetch("https://mantos.herokuapp.com/api/login/phone/otp",{
        method:'POST',
        body:JSON.stringify(item1),
        headers:{
            "Content-Type":'application/json',
            "Accept":'application/json'
        }
    }).then(data => data.json())

}

const handleSubmit = async e => {
    e.preventDefault();
    let phone=searchparams.get("phone");
    const response = await Login({
      phone,otp
    });
    if ( response.message==="[HTTP 400] Unable to create record: Invalid parameter: Code") {
     swal("Failed", response.message, "error");
    } else {
      swal("Success", response.message,"success", {
       buttons: false,
       timer: 2000,
     }).then((value) => {
      
         navigate({
           pathname:'/Profile'
         })
     });
    }
   }




async function resendOtp(){
        let phone=searchparams.get("phone")
        let item={phone,otp}
      let result=await fetch("https://mantos.herokuapp.com/api/resend-otp/phone",{
            method:'POST',
            body:JSON.stringify(item),
            headers:{
                "Content-Type":'application/json',
                "Accept":'application/json'
            }
        })
        result=await result.json()
        console.warn("result",result)
           
      }

  return (
    <div><div className="account-section bg_img bg10 py-5">
    <div className="container mt-5 py-5">
   
        <div className="account-wrapper">            
            <div className="account-body my-3">
                <span className="d-block mb-20 text-lg text-white text-bold">Verifiy your account</span>
                    <div className="form-group">
                        <label for="sign-up">Your Otp Number </label>
                        <form noValidate onSubmit={handleSubmit}>
            <input
             placeholder='Enter Your Otp Number'
            className='text-dark'
              value={otp}
              onChange={e => setOtp(e.target.value)}
            />
            <button
              className='btn-danger'
              type="submit">
              Sign In
            </button>
          </form>
                    </div>
                    <div className="form-group text-center">
                        <span className="d-block mt-15">Resend your code? <button className='btn-danger' onClick={resendOtp}>Resend</button></span>

                        <span className="d-block mt-15">Don't have an account? <Link to='/Signup'>Sign Up</Link></span>
                    </div>
               
            </div>
        </div>
        <div className="sponsor-slider-wrapper cl-white text-center mt-40">
            <h5 className="slider-heading mb-3">Used by over 1,000,000 people worldwide</h5>
            <div className="sponsor-slider-4 d-flex">
                <div className="sponsor-thumb">
                    <img src={Img3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img7} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img8} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img9} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img7} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img8} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img9} alt="sponsor"/>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
  )
}

export default Loginotp