import React from 'react'
import Img1 from '../assets/images/extra/01.png'

import Img2 from '../assets/images/extra/02.png'

import Img3 from '../assets/images/extra/03.png'
import Img4 from '../assets/images/feature/am-fea1.png'
import Img5 from '../assets/images/feature/am-fea2.png'
import Img6 from '../assets/images/feature/am-fea3.png'
import Img7 from '../assets/images/feature/am-fea4.png'

const Section7 = () => {
  return (
    <div>
    <section className="amazing-section padding-top-2 padding-bottom-2">
        <div className="container-fluid">
            <div className="section-header mw-725">
                <h5 className="cate">Cool & Amazing Design</h5>
                <h2 className="title">Amazing Mobile App</h2>
                <p>
                    We built it from ground up to give you an amazing experience
                </p>
            </div>
            <div className="amazing-slider pl-md-3 pl-2 owl-theme">
            <div className="amazing-thumb">
                <div className="container-fluid">
                    <div className="row">

                    <div className="col-md-4">
                <img src={Img1} width='100%'  alt="extra-2/amazing"/>
                </div>
                <div className="col-md-4">
                <img src={Img2} width='100%' alt="extra-2/amazing"/>

                </div>
                <div className="col-md-4">
                <img src={Img3} width='100%' alt="extra-2/amazing"/>

                </div>

                    </div>
                </div>         
                </div>
            </div>
        </div>
    </section>

    <section className="feature-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="feature--wrapper-17">
                <div className="row align-items-center flex-wrap-reverse position-relative">
                    <div className="col-lg-6">
                        <div className="row mb-30-none">
                            <div className="col-md-6">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img4} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Free Signup</h5>
                                    </div>
                                </div>
                                <div className="am-item active">
                                    <div className="am-thumb">
                                        <img src={Img5} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Secure Data</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-120">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img6} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Quick Access</h5>
                                    </div>
                                </div>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={Img7} alt="feature"/>
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">24h Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-header left-style mb-lg-0">
                            <h5 className="cate">An Exhaustive list of Amazing Features</h5>
                            <h2 className="title"> Fast transaction with secure data</h2>
                         
                            <p>Mantos App is completely a payment app with 100% secure your data and f you find any issue regarding your payment you can contact through customer support to solve your issue.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    </div>
  )
}

export default Section7