import React from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Img2 from '../../assets/images/feature/feat1.png'
import Img3 from '../../assets/images/feature/colaboration.png'
import Img4 from '../../assets/images/feature/feat2.png'
import Img6 from '../../assets/images/feature/feat3.png'
import Img8 from '../../assets/images/feature/feat4.png'
import Img10 from '../../assets/images/feature/feat5.png'
import Img11 from '../../assets/images/feature/productivity.png'
import Img12 from '../../assets/images/feature/feat6.png'

import Img14 from '../../assets/images/feature/feat7.png'

import Img16 from '../../assets/images/feature/feat8.png'
import Img17 from '../../assets/images/feature/feat9.png'
import Img18 from '../../assets/images/feature/integrations.png'
import Img19 from '../../assets/images/feature/feat10.png'
import Img20 from '../../assets/images/feature/feat11.png'
import Img21 from '../../assets/images/comunity/1.png'
import Img22 from '../../assets/images/comunity/2.png'
import Img23 from '../../assets/images/comunity/3.png'
import Img24 from '../../assets/images/comunity/4.png'
import Img25 from '../../assets/images/comunity/5.png'
import Img26 from '../../assets/images/comunity/6.png'
import Img27 from '../../assets/images/comunity/7.png'
import Img28 from '../../assets/images/comunity/8.png'
import Img29 from '../../assets/images/comunity/9.png'
import Img30 from '../../assets/images/comunity/12.png'
import Img31 from '../../assets/images/comunity/11.png'
import Img32 from '../../assets/images/comunity/12.png'
import Img33 from '../../assets/images/comunity/13.png'
import Img34 from '../../assets/images/comunity/14.png'
import Img35 from '../../assets/images/comunity/15.png'
import Img36 from '../../assets/images/comunity/16.png'
import Img37 from '../../assets/images/comunity/17.png'
import Img38 from '../../assets/images/comunity/18.png'
import Img39 from '../../assets/images/comunity/19.png'
import Img40 from '../../assets/images/comunity/20.png'
import Img41 from '../../assets/images/comunity/21.png'
import Img42 from '../../assets/images/comunity/22.png'
import Img44 from '../../assets/images/sponsor/sponsor1.png'

import Img45 from '../../assets/images/sponsor/sponsor2.png'

import Img46 from '../../assets/images/sponsor/sponsor3.png'

import Img47 from '../../assets/images/sponsor/sponsor4.png'

import Img48 from '../../assets/images/sponsor/sponsor5.png'
import Img49 from '../../assets/images/sponsor/sponsor7.png'

import Img50 from '../../assets/images/sponsor/sponsor6.png'
import {AiFillCaretRight} from "react-icons/ai"
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;


const Featurehero = () => {
  return (
    <div>
      <Toaster />

<section className="page-header bg_img contactbg">
        <div className="bottom-shape d-none d-md-block">
            <img src={Img1} alt="css"/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title">Features</h2>
       
            </div>
        </div>
    </section>



    <section className="feature-section padding-top padding-bottom oh">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-8">
                    <div className="section-header mw-100">
                        <h5 className="cate">Mantos App providing a list of Amazing Features</h5>
                        <h2 className="title">A New Way To Transfer Move Your Money Safely And Securely</h2>
                        
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs feature-tab-menu">
                <li>
                    <a  data-toggle="tab" href="#collaboration" className="active">Collaboration</a>
                </li>
                <li>
                    <a  data-toggle="tab" href="#productivity">Productivity</a>
                </li>
                <li>
                    <a  data-toggle="tab" href="#integrations">Integrations</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="collaboration">
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <span className="cate">Collaboration</span>
                                    <h5 className="title">instant Cash In and Cashout also refund payment any time without effected your weekly or monthly limit</h5>
                                    <ul>
                                        <li>One Click Deposite</li>
                                        <li>Instant Payment Transfer</li>
                                        <li>Instant Paayment Withdraw</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img2} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <span className="cate">Collaboration</span>
                                    <h5 className="title">Cyber Security protected</h5>
                                    <ul>
                                        <li>High Security</li>
                                        <li>5 Layer Security</li>
                                        <li>Verfication Security</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img4} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <span className="cate">Collaboration</span>
                                    <h5 className="title">Transfer your payment any time through mantos app</h5>
                                    <ul>
                                        <li>Easy To Use</li>
                                        <li>Easily Payment Transfer</li>
                                        <li>Instant Transfer</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img6} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <span className="cate">Collaboration</span>
                                    <h5 className="title">We are using latest IT technologies and programing languages to make the App fast and secure</h5>
                                    <ul>
                                        <li>Laravel</li>
                                        <li>React</li>
                                        <li>ReactJs</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img8} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img3} alt="feature"/>
                                    </div>
                                    <span className="cate">Collaboration</span>
                                    <h5 className="title">Share important news and updates with the entire friends</h5>
                                    <ul>
                                        <li>Chat System</li>
                                        <li>App Update</li>
                                        <li>Sending email updates </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img10} alt="feature"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="productivity">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-tab-header">
                                <h3 className="title">productivity</h3>
                                <p>Boost your team's productivity and efficiency with our inbuilt 
                                    tools. Collaborate with team members, share opinions, 
                                    and manage your tasks more efficiently.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img11} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Convert discussions to tasks 
                                        instantly</h5>
                                    <ul>
                                        <li>creating to-dos</li>
                                        <li>Increase the conversion efficiency</li>
                                        <li>Make checklists</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img12} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img11} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Create notes and collaborate
                                        with your team on the go</h5>
                                    <ul>
                                        <li>Notes feature</li>
                                        <li>Share ideas quickly</li>
                                        <li>Enhance team collaboration</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img14} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img11} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Missed a deadline?
                                        Never again</h5>
                                    <ul>
                                        <li>Never miss another deadline</li>
                                        <li>Set a reminder</li>
                                        <li>Reminders set for all </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img16} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img11} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Streamline and automate 
                                        your workflows</h5>
                                    <ul>
                                        <li>Simplify and automate</li>
                                        <li>Receive real-time updates</li>
                                        <li>Enhance team collaboration</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img17} alt="feature"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="integrations">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-tab-header">
                                <h3 className="title">integrations</h3>
                                <p>Integrate your most frequently used third-party apps
                                    with Mosto. Choose from over 60+ apps listed
                                    on our Appstore or build your own.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img18} alt="feature"/>
                                    </div>
                                    <span className="cate">Integrations</span>
                                    <h5 className="title">Access your entire Google
                                        Drive from within Mosto</h5>
                                    <ul>
                                        <li>Collaborate with Google Drive's</li>
                                        <li>Increase the conversion efficiency</li>
                                        <li>Make checklists</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img19} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Img18} alt="feature"/>
                                    </div>
                                    <span className="cate">Integrations</span>
                                    <h5 className="title">Reach every meeting on 
                                        time with Google Calendar</h5>
                                    <ul>
                                        <li>Collaborate with Google Drive's</li>
                                        <li>Increase the conversion efficiency</li>
                                        <li>Make checklists</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Img20} alt="feature"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    
    
    <section className="comunity-section padding-top padding-bottom oh pos-rel">
        <div className="comunity-bg bg_img bg6"></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-header cl-white">
                        <h5 className="cate">Join to comunity</h5>
                        {/* <h2 className="title">Over 1 000 000 users</h2> */}
                        <p>Join and share in a community of like-minded members</p>
                    </div>
                </div>
            </div>
            <div className="comunity-wrapper">
                <div className="buttons"><a onClick={notify} className="button-3 active">Join to comunity <AiFillCaretRight/></a></div>
                <div className="comunity-area">
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img36} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img37} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img38} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img39} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img40} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img41} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img42} alt="comunity"/></div>
                </div>
                <div className="comunity-area two">
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img36}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img37} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img38} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img39} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img40} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img41} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img42} alt="comunity"/></div>
                </div>
                <div className="comunity-area three">
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img36} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img37} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img38} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img39} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img40} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img41} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img42} alt="comunity"/></div>
                </div>
            </div>
        </div>
    </section>
    
    
    <section className="sponsor-section padding-bottom">
        <div className="container">
            <div className="section-header mw-100">
                {/* <h5 className="cate">Used by over 1,000,000 people worldwide</h5> */}
                <h2 className="title">Companies that trust us</h2>
            </div>
            <div className="sponsor-slider-4 d-flex">
               
                <div className="sponsor-thumb">
                    <img src={Img44} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img45} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img46} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img47} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img48} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img49} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={Img50} alt="sponsor"/>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Featurehero