import React from 'react'
import Img1 from "../assets/images/404/man_01.png"
import Img2 from "../assets/images/404/man_02.png"
import {Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div>
          <div className="error-section bg_img" data-background="assets/images/404/404-bg.jpg">
        <div className="container">
            <div className="man1">
                <img src={Img1} alt="404" className="wow bounceInUp" data-wow-duration=".5s" data-wow-delay=".5s"/>
            </div>
            <div className="man2">
                <img src={Img2} alt="404" className="wow bounceInUp" data-wow-duration=".5s"/>
            </div>
            <div className="error-wrapper wow bounceInDown" data-wow-duration=".7s" data-wow-delay="1s">
                <h1 className="title">404</h1>
                <h3 className="subtitle">Page Not Found</h3>
                <Link to="/" className="button-5">Go Back</Link>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PageNotFound