import React from 'react'
import Hero from '../componats/Hero'

import Section1 from "../componats/Section1";
import Section2 from "../componats/Section2";
import Section3 from "../componats/Section3";
import Section4 from "../componats/Section4";
import Section5 from "../componats/Section5";
import Section6 from "../componats/Section6";
import Section7 from "../componats/Section7";
import Bottom from "../componats/Bottom";
import Section8 from "../componats/Section8";

const Home = () => {
  return (
    <div>

<Hero/>
    <Section1/>
    <Section8/>
    <Section7/>
    <Section2/>
    <Section3/>
    <Section4/>
    {/* <Section5/> */}
    <Section6/>
    <Bottom/> 


    </div>
  )
}

export default Home