import React from 'react'
import Img1 from '../assets/images/extra-2/screenshot/background.png'
import Img2 from '../assets/images/extra-2/screenshot/group1.png'
import {DiAndroid} from 'react-icons/di'
import {BsApple} from 'react-icons/bs'

import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;

const Section4 = () => {
  return (
    <div><section className="apps-screenshot-section-20 padding-top padding-bottom pb-max-lg-0 overflow-hidden position-relative">
    <div className="app-screenshot-20 d-none d-lg-flex">
        <img src={Img1} style={{zIndex:'-1'}} alt="extra-2/screenshot"/>
    </div>
    <div className="container-fluid">
        <div className="row justify-content-lg-between justify-content-center align-items-center flex-wrap-reverse">
            <div className="col-lg-7">
                <div className="app-screenshot-slider-20">
                    <div className="thumbs">
                        <img src={Img2} width="100%" alt="extra-2/screenshot"/>
                    </div>
                   
                </div>
            </div>
            <div className="col-lg-5">
                <div className="mb-3 mb-lg-0">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Cash Card</h5>
                        <h2 className="title">Linked your card on Mantos App</h2>
                        <p>Link your card in mantos app while giving some credentials and after adding card you set the pin code for payout</p>
                    </div>
                    <h6 className="title mb-4">Ready to get started?</h6>
                    <ul className="download-options justify-content-start download-option-20">
                        <li>
                            <a onClick={notify} className="active"><DiAndroid/></a>
                            <span>Android</span>
                        </li>
                        <li>
                            <a onClick={notify} className='active'><BsApple/></a>
                            <span>ios</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


    </div>
  )
}

export default Section4