import React,{useState} from 'react'
import Img2 from '../../assets/images/icon/google.png'
import {createSearchParams, Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Siginin = () => {

const [phone,setPhone]=useState("")
const navigate=useNavigate();

async function Login(){
    let item1={phone}
    console.warn(item1)

 return fetch("https://mantos.herokuapp.com/api/login/phone",{
        method:'POST',
        body:JSON.stringify(item1),
        headers:{
            "Content-Type":'application/json',
            "Accept":'application/json'
        }
    }).then(data => data.json())

}

// async function loginUser() {
//     return fetch('https://mantos.herokuapp.com/api/login/phone', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(phone)
//     })
//       .then(data => data.json())
//    }

 
   const handleSubmit = async e => {
     e.preventDefault();
     const response = await Login({
       phone
     });
     if ( response.message==="Verification code sent to your phone number") {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
       
          navigate({
            pathname:'/Loginotp',
            search:createSearchParams({
              phone:phone
            }).toString()
          })
      });
     } else {
      swal("Failed", response.message, "error");
     }
    }


  return (
    <div>

<div className="account-section bg_img bg10 py-5">
        <div className="container mt-5 py-5">
          
            <div className="account-wrapper">
                <div className="account-body">
                    <h4 className="title mb-20">Welcome To Mantos</h4>
                    
                    <form noValidate onSubmit={handleSubmit}>
                    <PhoneInput
                    className='text-dark'
                    defaultCountry="US"
      placeholder="Enter phone number"
      value={phone}
      onChange={setPhone}/>
            {/* <input
            placeholder='Enter Phone Number'
            className='text-dark'
              value={phone}
              onChange={e => setPhone(e.target.value)}
            /> */}
            <button
              type="submit" className='btn-danger mt-2'>
              Sign In
            </button>
          </form>
                </div>
                <div className="or">
                    <span>OR</span>
                </div>
                <div className="account-header pb-0">
                    <span className="d-block mb-30 mt-2">Sign up with your work email</span>
                    <a href="#0" className="sign-in-with"><img src={Img2} alt="icon"/><span>Sign Up with Google</span></a>
                    <span className="d-block mt-15">Don't have an account? <Link to="/Signup">Sign Up Here</Link></span>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}

export default Siginin