import React from 'react'
import Img1 from '../assets/images/extra-2/how/1.png'
import Img2 from '../assets/images/extra-2/how/2.png'
import Img3 from '../assets/images/extra-2/how/3.png'
import Img4 from '../assets/images/extra-2/feature/phone2.png'

const Section3 = () => {
  return (
    <div><section className="how-section-20 ovelflow-hidden px-lg-4">
    <div className="container-fluid ml-5 mr-5">
        <div className="row align-items-end">
            <div className="col-lg-4">
                <div className="move-top--lg-70">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Transaction History</h5>
                        <h2 className="title">In & Out Transaction History</h2>
                        <p>Here you will get your all in & out transaction history which is very important factor in payment app</p>
                    </div>
                    <div className="how-item-wrapper-20">
                        <div className="how-item-20">
                            <h6 className="title">Create an Account</h6>
                            <div className="how-thumb">
                                <img src={Img1} className="resimg" alt="how"/>
                            </div>
                        </div>
                        <div className="how-item-20">
                            <h6 className="title">Configure</h6>
                            <div className="how-thumb">
                                <img src={Img2} alt="how"/>
                            </div>
                        </div>
                        <div className="how-item-20">
                            <h6 className="title">Automation</h6>
                            <div className="how-thumb">
                                <img src={Img3} alt="how"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="how-slider-wrapper mt-4 mt-lg-0 ml--lg-78 mr--lg-65">
                    <div className="how-slider-20">
                        <div className="thumbs">
                            <img src={Img4} alt="extra-2/feature"/>
                        </div>
                    
                    </div>
                    <div className="dots-2"></div>
                </div>
            </div>
        </div>
    </div>
</section></div>
  )
}

export default Section3