import React from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Accordion from 'react-bootstrap/Accordion';
import Img2 from '../../assets/images/feature/help.png'
import Img3 from '../../assets/images/feature/help1.png'
import Img4 from '../../assets/images/feature/help2.png'
const Faqs = () => {
  return (
    <div>
          {/* <section className="page-header bg_img contactbg">
        <div className="bottom-shape d-none d-md-block">
            <img src={Img1} alt="css"/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title">Questions & Answers</h2>
  
            </div>
        </div>
    </section> */}


    {/* <section className="faq-section padding-top padding-bottom">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-4">
                    <aside className="sticky-menu">
                        <div className="faq-menu bg_img mb-30 bg11">
                            <ul id="faq-menu">
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#get">Getting Started</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#how">How Mosto works</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#plugin">Plugins</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#pricing">Pricing Plans</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#update">Updating the App</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#security">Security</a>
                                </li>
                            </ul>
                        </div>
                   
                    </aside>
                </div>
                <div className="col-lg-8 col-xl-7">
                    <article className="mt-70 mt-lg-0">
                        <div className="faq--wrapper" id="get">
                            <h3 className="main-title">Getting Started</h3>
                            <div className="faq--area">
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                        <div className="faq--wrapper" id="how">
                            <h3 className="main-title">How Mosto works</h3>
                            <div className="faq--area">
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                        <div className="faq--wrapper" id="plugin">
                            <h3 className="main-title">Plugins</h3>
                            <div className="faq--area">
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                        <div className="faq--wrapper" id="pricing">
                            <h3 className="main-title">Pricing Plans</h3>
                            <div className="faq--area">
                              <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                        <div className="faq--wrapper" id="update">
                            <h3 className="main-title">Updating the App</h3>
                            <div className="faq--area">
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                        <div className="faq--wrapper" id="security">
                            <h3 className="main-title">Security</h3>
                            <div className="faq--area">
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="faq--item">
        <Accordion.Header className='faq-title tite text-white bg-none'>Accordion Item #1</Accordion.Header>
        <Accordion.Body className='text-white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='faq--item'>
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
     */}
    
    <section className="comunity-section padding-top padding-bottom oh pos-rel">
        <div className="comunity-bg bg_img bg6"></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="section-header cl-white">
                        <h5 className="cate">Still need help?</h5>
                        <h2 className="title">Stop Wasting Time</h2>
                        <p>Whether you’re stuck or just want some tips on where to start, any problem,hit up our experts anytime.</p>
                    </div>
                </div>
            </div>
            <div className="comunity-asking">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6 rtl d-none d-lg-block">
                        <img src={Img2} alt="feature"/>
                    </div>
                    <div className="col-lg-6 col-xl-5 mb-30-none">
                        <div className="help-item">
                            <div className="help-thumb">
                                <img src={Img3} alt="help"/>
                            </div>
                            <div className="help-content">
                                <h5 className="title">live chat</h5>
                                <p><a href="mailTo:contact@mantos.app">Anything I can help you with?</a></p>
                            </div>
                        </div>
                        <div className="help-item">
                            <div className="help-thumb">
                                <img src={Img4} alt="help"/>
                            </div>
                            <div className="help-content">
                                <h5 className="title">Send Ticket</h5>
                                <p><a href="mailTo:contact@mantos.app">Anything I can help you with?</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Faqs