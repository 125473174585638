import React from 'react'
import Contacthero from './Contacthero'

const Contact = () => {
  return (
    <>
    <Contacthero/>
    </>
  )
}

export default Contact