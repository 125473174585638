import React from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Img2 from '../../assets/images/team/team1.png'
import Img3 from '../../assets/images/team/team2.png'
import Img4 from '../../assets/images/team/team3.png'

import Img5 from '../../assets/images/team/team4.png'

import Img6 from '../../assets/images/team/team5.png'

import Img7 from '../../assets/images/team/team6.png'
import Img8 from '../../assets/images/team/team7.png'
import Img9 from '../../assets/images/team/team8.png'
import Img10 from '../../assets/images/team/team9.png'
import Img11 from '../../assets/images/bg/calculate-bg.png'
import Img12 from '../../assets/images/button/google.png'
import Img13 from '../../assets/images/button/apple.png'
const Teamhero = () => {
  return (
    <div>
         <section class="page-header bg_img contactbg">
        <div class="bottom-shape d-none d-md-block">
            <img src={Img1} alt="css"/>
        </div>
        <div class="container">
            <div class="page-header-content cl-white">
                <h2 class="title">Team Member</h2>
              
            </div>
        </div>
    </section>


    <section class="team-section padding-top padding-bottom oh">
        <div class="container">
            <div class="section-header">
                <h5 class="cate">Meet our most valued</h5>
                <h2 class="title">Expert Team Members</h2>
                <p>
                    Our team of creative programmers,marketing experts, and members .we are to be doing what we love.
                </p>
            </div>
            <div class="row justify-content-center mb-40-none">
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img2} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Alex Love</a>
                            </h4>
                            <span class="info">Chief executive officer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img3} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Kathy Holt</a>
                            </h4>
                            <span class="info">Growth Marketer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img4} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Steven Mann</a>
                            </h4>
                            <span class="info">iOS Developer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img5} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Rosa Moss</a>
                            </h4>
                            <span class="info">Chief executive officer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img6} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Jeff Silva</a>
                            </h4>
                            <span class="info">Growth Marketer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img7} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Sherry Parks</a>
                            </h4>
                            <span class="info">Product Designer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img8} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Nancy Stone</a>
                            </h4>
                            <span class="info">Chief executive officer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img9} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Faye Delgado</a>
                            </h4>
                            <span class="info">Culture Manager</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src={Img10} alt="team"/>
                        </div>
                        <div class="team-content">
                            <h4 class="title">
                                <a href="#0">Pedro Hill</a>
                            </h4>
                            <span class="info">iOS Developer</span>
                            <a href="#0" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <div class="padding-bottom get-started-section">
        <div class="custom-plan oh">
            <div class="container">
                <div class="custom-wrapper">
                    <div class="calculate-bg">
                        <img src={Img11} alt="bg"/>
                    </div>
                    <div class="custom-area pt-20 pb-20">
                        <div class="section-header cl-white pb-20">
                            <h3 class="title">Download Mosto Today And Get Started With A Free Trial For Your Business</h3>
                        </div>
                        <div class="app-button-group justify-content-center">
                            <a href="#0" class="app-button">
                                <img src={Img12} alt="button"/>
                            </a>
                            <a href="#0" class="app-button active">
                                <img src={Img13} alt="button"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Teamhero