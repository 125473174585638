import React from 'react'
import Aboutpage from './Aboutpage'
const About = () => {
  return (
    <div>

<Aboutpage/>

    </div>
  )
}

export default About