import React from 'react'
import Img1 from "../assets/images/banner/dot-big.png"
import Img2 from "../assets/images/banner/dot-big.png"
import Img3 from "../assets/images/banner/dot-sm.png"
import Img4 from "../assets/css/img/banner1-shape.png"
import Img5 from "../assets/images/banner/banner1-1.png"
import { Link } from 'react-router-dom'

import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;
  
const Hero = () => {
   
  return (
    <div>

<section className="banner-1 bg_img oh bg1 px-lg-5">
        <div className="dot-1 d-none d-lg-block">
            <img src={Img1} alt="banner"/>
        </div>
        <div className="dot-2 d-none d-lg-block">
            <img src={Img2} alt="banner"/>
        </div>
        <div className="dot-3">
            <img src={Img3} alt="banner"/>
        </div>
        <div className="dot-4">
            <img src={Img3} alt="banner"/>
        </div>
        <div className="banner-1-shape d-none d-lg-block">
            <img src={Img4} alt="css"/>
        </div>
        <div className="container-fluid ml-5 mr-5 ">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-5 ">
                    <div className="banner-content-1 cl-white">
                        <h1 className="title">Instantly Sending and Receiving Payments Is Now Easy Through Mantos</h1>
                        <p>
                            The smartest and easy way to send and received payments in a secure way.
                        </p>
                        <div className="banner-button-group">
                            <Link onClick={notify} className="button-4">Chatting Room</Link>

                            <Link onClick={notify} className="button-4 active">Signup</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 text-sm-center">
                    <div className="banner-1-slider-wrapper">
                        <div className="banner-1-slider">
                            <div className="banner-thumb homgimg">
                                <img src={Img5} alt="banner"/>
                            </div>
                            
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Hero