import React,{ useRef } from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Img2 from '../../assets/images/contact/man.png'
import Img3 from '../../assets/images/contact/contact1.png'
import Img4 from '../../assets/images/contact/contact3.png'
import Img5 from '../../assets/images/contact/contact2.png'
import Img6 from '../../assets/images/contact/earth.png'
import {AiOutlineRight} from 'react-icons/ai'
import {AiOutlineLeft} from 'react-icons/ai'
import {Link } from "react-router-dom";
import emailjs from '@emailjs/browser';

const Contacthero = () => {
    const form = useRef();

    

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1n0bi17', 'template_6gq8lcc',form.current, 'GDFfNIEe2MgeyAteR')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div>

    <section className="page-header single-header bg_img oh contactbg">
        <div className="bottom-shape d-none d-md-block">
            <img src={Img1} alt="css"/>
        </div>
    </section>

    <section className="contact-section padding-top padding-bottom">
        <div className="container">
            <div className="section-header mw-100 cl-white">
                <h2 className="title">Contact Us</h2>
                <p>Whether you're looking for a demo, have a support question or a commercial query get in touch.</p>
            </div>
            <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-7">
                    <div className="contact-wrapper">
                        <h4 className="title text-center mb-30">Get in Touch</h4>
                        <form className="contact-form"  ref={form} onSubmit={sendEmail} id="contact_form_submit">
                            
                            <div className="form-group">
                                <label for="name">Your Full Name</label>
                                <input type="text" placeholder="Enter Your Full Name" name='from_name' id="name" required/>
                            </div>
                            
                            <div className="form-group">
                                <label for="email">Your Email </label>
                                <input type="text" placeholder="Enter Your Email " name='email_id' id="email" required/>
                            </div>
                            <div className="form-group">
                                <label for="subject">Your Subject </label>
                                <input type="text" placeholder="Enter Your Subject " name='subject' id="subject" required/>
                            </div>
                           
                            <div className="form-group mb-0">
                                <label for="message">Your Message </label>
                                <textarea id="message" name='message' placeholder="Enter Your Message" required></textarea>
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Send Message"/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="contact-content">
                        <div className="man d-lg-block d-none">
                            <img src={Img2} alt="bg"/>
                        </div>
                        <div className="section-header left-style">
                            <h3 className="title">Have questions?</h3>
                            <p>Have questions about payments or price 
                                plans? We have answers!</p>
                            <a href="#0">Read F.A.Q <AiOutlineRight/></a>
                        </div>
                        <div className="contact-area">
                            <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={Img3} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Email Us</h5>
                                    <a href="Mailto:info@mosto.com"><span className="__cf_email__" data-cfemail="ef86818980af82809c9b80c18c8082">contact@mantos.app</span></a>
                                </div>
                            </div>
                            {/* <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={Img5} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Call Us</h5>
                                    <a href="Tel:+13465990485">+1 (346) 599-0485 </a>
                                </div>
                            </div> */}
                            <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={Img4} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Visit Us</h5>
                                    <p>54 STATE STREET, STE 804 ALBANY, NY, 12207, USA</p>
                                    <br/>
                                    <p>17350 STATE HWY 249, #220 HOUSTON, TX, 77064, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className="map-section padding-bottom-2">
        <div className="container">
            <div className="section-header">
                <div className="thumb">
                    <img src={Img6} alt="contact"/>
                </div>
                <h3 className="title">We Are Easy To Find</h3>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="maps-wrapper">
                    <iframe style={{position:'absolute'}}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26361414.263400003!2d-113.75849480805297!3d36.24080384347216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1663581136028!5m2!1sen!2s"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"

            />

                        <div className="maps" ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="do-section padding-bottom padding-top-2">
        <div className="container">
            <div className="row mb-30-none justify-content-center">
                <div className="col-md-6">
                    <div className="do-item cl-white">
                        <h3 className="title">About Us</h3>
                        <p>Find out who we are and what we do!</p>
                        <Link to="/About"><AiOutlineLeft/></Link>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="do-item cl-white">
                        <h3 className="title">Partners</h3>
                        <p>Become a Mantos Partner!</p>
                            <Link to="/Contact"><AiOutlineRight/></Link>

                    </div>
                </div>
            </div>
        </div>
    </div>

    

    </div>
  )
}

export default Contacthero