import "./assets/css/minified.css"
import Home from "./componats/Home";
import Contact from "./componats/contact/Contact";
import Footer from "./componats/Footer";
import About from "./componats/About/About";
import Team from "./componats/Team/Team";
import Feature from "./componats/feature/Feature";
import Appdownload from "./componats/Appdownload/Appdownload";
import Siginin from "./componats/Signin/Siginin";
import Signup from "./componats/Signup/Signup";
import Changepassword from "./componats/changepassword/Changepassword";
import Faqs from "./componats/faqs/Faqs";
import Privacypolicy from "./componats/privacypolicy/Privacypolicy";
import Resetpassword from "./componats/resetpassword/Resetpassword";
import Navbar from "./componats/navbar/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./componats/ScrollToTop";
import Registerotp from "./componats/registerotp/Registerotp";
import PageNotFound from "./componats/PageNotFound";
import Loginotp from "./componats/Loginotp/loginOtp"
import Profile from "./componats/profile/Profile";
import Userinfo from "./componats/userinfo/Userinfo";


function App() {

  return (
    <BrowserRouter>
    <div>
      <Navbar/>

      <ScrollToTop>
<Routes>


  <Route path='/' element={<Home/>}/>
  <Route path='Contact' element={<Contact/>}/>
  <Route path='About' element={<About/>}/>
  <Route path='Team' element={<Team/>}/>
  <Route path='Feature' element={<Feature/>}/>
  <Route path='Appdownload' element={<Appdownload/>}/>
  <Route path='Signin' element={<Siginin/>}/>
  <Route path='Signup' element={<Signup/>}/>
  <Route path='Changepassword' element={<Changepassword/>}/>
  <Route path='Faqs' element={<Faqs/>}/>
  <Route path='Privacypolicy' element={<Privacypolicy/>}/>
  <Route path='Resetpassword' element={<Resetpassword/>}/> 
  <Route path='Registerotp' element={<Registerotp/>}/> 
  <Route path="*" element={<PageNotFound/>}/>
  <Route path="/Loginotp" element={<Loginotp/>}/>
  <Route path="/profile" element={<Profile/>}/>
  <Route path="/Userinfo" element={<Userinfo/>}/>


</Routes>
</ScrollToTop>

    <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
