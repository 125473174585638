import React from 'react'
import Img2 from '../assets/images/logo/mantos-logowhite.png'
import { Link } from 'react-router-dom'
import {FaFacebookF} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {FaPinterestP} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {FiYoutube} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'
const Footer = () => {
  return (
    <div>

<footer className="footer-section bg_img bg4">
        <div className="container">
            <div className="footer-top padding-top padding-bottom">
                <div className="logo">
                    <Link to="/">
                        <img src={Img2} alt="logo"/>
                    </Link>
                </div>
                <ul className="social-icons">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61554865320803" target='_blank' className="active"><FaFacebookF/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/MantosApp" target='_blank' className="active"><BsTwitter/></a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.com/mantosapp/" target='_blank' className="active"><FaPinterestP/></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/mantosapp/" target='_blank' className="active"><BsInstagram/></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@MantosApp" target='_blank' className="active"><FiYoutube/></a>
                    </li>
                    
                </ul>
            </div>
            <div className="footer-bottom">
                <ul className="footer-link">
                    <li>
                        <Link to="/About">About</Link>
                    </li>
                    <li>
                        <Link to="Faqs">FAQs</Link>
                    </li>
                    <li>
                        <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Privacypolicy">Privacy</Link>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <p>
                    Copyright © 2022.All Rights Reserved By <Link to="/">Mantos</Link>
                </p>
            </div>
        </div>
    </footer>

    </div>
  )
}

export default Footer