import React from 'react'
import Img1 from '../../assets/images/button/google.png'
import Img2 from '../../assets/images/button/apple.png'
import Img3 from '../../assets/images/banner/app/app1.png'

import Img7 from '../../assets/images/sponsor/sponsor1.png'
import Img8 from '../../assets/images/sponsor/sponsor2.png'
import Img9 from '../../assets/images/sponsor/sponsor3.png'
import Img10 from '../../assets/images/sponsor/sponsor4.png'
import Img11 from '../../assets/images/sponsor/sponsor5.png'
import Img12 from '../../assets/images/sponsor/sponsor6.png'
import Img13 from '../../assets/images/sponsor/sponsor7.png'
import Img14 from '../../assets/images/comunity/1.png'
import Img15 from '../../assets/images/comunity/2.png'
import Img16 from '../../assets/images/comunity/3.png'
import Img17 from '../../assets/images/comunity/4.png'
import Img18 from '../../assets/images/comunity/5.png'

import Img19 from '../../assets/images/comunity/6.png'

import Img20 from '../../assets/images/comunity/7.png'

import Img21 from '../../assets/images/comunity/8.png'

import Img22 from '../../assets/images/comunity/9.png'

import Img23 from '../../assets/images/comunity/10.png'

import Img24 from '../../assets/images/comunity/11.png'

import Img25 from '../../assets/images/comunity/12.png'

import Img26 from '../../assets/images/comunity/13.png'

import Img27 from '../../assets/images/comunity/14.png'

import Img28 from '../../assets/images/comunity/15.png'

import Img29 from '../../assets/images/comunity/16.png'
import Img30 from '../../assets/images/comunity/17.png'
import Img31 from '../../assets/images/comunity/18.png'
import Img32 from '../../assets/images/comunity/19.png'
import Img33 from '../../assets/images/comunity/20.png'
import Img34 from '../../assets/images/comunity/21.png'

import Img35 from '../../assets/images/comunity/22.png'
import {AiFillCaretRight} from 'react-icons/ai'


import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Coming Soon', {
    style: {
      border: '1px solid #713200',
      marginTop:'100px',
      fontSize:'20px',
      color: 'white',
      background: 'linear-gradient(90deg, rgba(56,141,235,1) 0%, rgba(25,64,129,1) 57%)'
    },
    iconTheme: {
      primary: '#031A4F',
      secondary: '#FFFAEE',
    },
  });;

const Appdownloadhero = () => {
  return (
    <div>
      <Toaster />

<section className="app-download-section">
        <div className="app-bg bg_img bg7">
                    </div>
        <div className="oh app-download">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="app-download-content cl-white">
                            <h5 className="cate">Success Start With A Great App</h5>
                            <h1 className="title">Mantos App easier way of transaction</h1>
                            <p>
                                The simple, intuitive and powerful  app that supports all modern devices and platforms.
                            </p>
                            <div className="app-button-group">
                                <a onClick={notify} className="app-button">
                                    <img src={Img1} alt="button"/>
                                </a>
                                <a onClick={notify} className="app-button">
                                    <img src={Img2} alt="button"/>
                                </a>
                            </div>
                            {/* <span className="joined">People already joined</span>
                            <h2 className="amount"><span className="counter">455,326,234</span></h2> */}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="banner-1-slider-wrapper">
                            <div className="banner-1-slider">
                                <div className="banner-thumb">
                                    <img src={Img3} alt="banner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

 

    <section className="sponsor-section padding-bottom padding-top-2 pt-max-lg-0">
        <div className="container">
            <div className="section-header mw-100">
                {/* <h5 className="cate mt-0">Used by over 1,000,000 people worldwide</h5> */}
                <h2 className="title">Companies that trust us</h2>
            </div>
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="sponsor-slider-4 owl-theme owl-carousel">
                            <div className="sponsor-thumb">
                                <img src={Img7} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img8} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img9} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img10} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img11} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img12} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img13} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img7} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img8} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img9} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img10} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img11} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img12} alt="sponsor"/>
                            </div>
                            <div className="sponsor-thumb">
                                <img src={Img13} alt="sponsor"/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>


    <section className="trial-section padding-bottom">
        <div className="container">
            <div className="trial-wrapper padding-top padding-bottom bg_img style-three bg8">
                <div className="trial-content cl-white">
                    <h3 className="title m-0">Are You Want to Join Our Team?</h3>
                </div>
                <div className="trial-button">
                    <a onClick={notify} className="button-3 long-light active text-light">JOIN OUR TEAM <AiFillCaretRight/></a>
                </div>
            </div>
        </div>
    </section>
    
    
    <section className="comunity-section padding-top padding-bottom oh pos-rel">
        <div className="comunity-bg bg_img bg9" ></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-header cl-white">
                        <h5 className="cate">Join to comunity</h5>
                        {/* <h2 className="title">Over 1 000 000 users</h2> */}
                        <p>Join and share in a community of like-minded members</p>
                    </div>
                </div>
            </div>
            <div className="comunity-wrapper">
                <div className="buttons"><a onClick={notify} className="button-3 active">Join to comunity <AiFillCaretRight/></a></div>
                <div className="comunity-area">
                    <div className="comunity-item"><img src={Img14} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img15} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img16} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img17} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img18} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img19} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img20} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35} alt="comunity"/></div>
                </div>
                <div className="comunity-area two">
                    <div className="comunity-item"><img src={Img14} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img15} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img16} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img17} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img18} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img19} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img20} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35}  alt="comunity"/></div>
                </div>
                <div className="comunity-area three">
                    <div className="comunity-item"><img src={Img14} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img15} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img16} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img17} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img18} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img19} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img20} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img21} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img22} alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img23}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img24}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img25}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img26}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img27}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img28}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img29}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img30}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img31}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img32}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img33}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img34}  alt="comunity"/></div>
                    <div className="comunity-item"><img src={Img35}  alt="comunity"/></div>
                </div>
            </div>
        </div>
    </section>


    </div>
  )
}

export default Appdownloadhero