import React from 'react'
import Img1 from '../../assets/images/logo/logo.png'
const Changepassword = () => {
  return (
    <div>

<div className="account-section bg_img bg10">
        <div className="container">
            <div className="account-title text-center mb-120">
                <a to="/home" className="back-home"><i className="fas fa-angle-left"></i><span>Back <span className="d-none d-sm-inline-block">To Mosto</span></span></a>
                <a href="#0" className="logo">
                    <img src={Img1} alt="logo"/>
                </a>
            </div>
            <div className="account-wrapper top-gap">
                <div className="account-body">
                    <div className="form-head">
                        <h4 className="title mb-20">Change Password</h4>
                        <p>You can change your password for security reasons or reset it if you forget it.</p>
                    </div>
                    <form className="account-form">
                        <div className="form-group">
                            <label for="new-pass">New Password</label>
                            <input type="password" placeholder="Enter New Password" id="new-pass"/>
                        </div>
                        <div className="form-group">
                            <label for="confirm-pass">Confirm Password</label>
                            <input type="password" placeholder="Confirm Password" id="confirm-pass"/>
                        </div>
                        <div className="form-group text-center">
                                <button type="submit" className="mt-40 mb-2 btn-danger">Change Password</button>
                        </div>
                    </form>
                    <span className="d-block mt-15">Remember your password? <a to="/signin"> Log In</a></span>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}

export default Changepassword