import React from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Img2 from '../../assets/images/bg/privacy-header.png'

const Privacypolicy = () => {
    return (
        <div>
            <section className="page-header bg_img oh contactbg">
                <div className="bottom-shape d-none d-md-block">
                    <img src={Img1} alt="css" />
                </div>
                <div className="page-left-thumb">
                    <img src={Img2} alt="bg" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Our Privacy Policy</h2>
                    </div>
                </div>
            </section>

            <section className="privacy-section padding-top padding-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header">
                                <h2 className="title">Privacy Policy</h2>
                                <p>Please read this Privacy Policy carefully before using this website</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-lg-4">
                            <aside className="sticky-menu">
                                <div className="faq-menu bg_img bg12">
                                    <ul id="faq-menu">
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#general">General</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#prop">Information We Collect</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#secu">How We Use Your Information</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#datasecurity">Data Security</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#info">Information Sharing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#cooki">Security</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#ipaddr">Updates to this Privacy Policy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#thirdparty">Third-Party Sharing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#yourchoices">Your Choices</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" href="#contactus">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-8 col-xl-7">
                            <article className="mt-70 mt-lg-0">
                                <div className="privacy-item" id="general">
                                    <h3 className="title">General</h3>
                                    <p>Welcome to Mantos, your trusted peer-to-peer (P2P) app designed to redefine the way you experience seamless and secure transactions. This Privacy Policy aims to provide clarity on how we collect, use, disclose, and safeguard your personal information.</p>
                                </div>
                                <div className="privacy-item" id="prop">
                                    <h3 className="title">Information We Collect</h3>
                                    <p>Personal Information: When you register with Mantos, we gather essential details like your name, email address, and contact information to personalize your experience.

                                        Transaction Data: To enhance the efficiency of P2P transactions, we collect data related to your transactions, including amounts, recipients, and timestamps.

                                        Device Information: Mantos may collect information about the device you use to access our app, such as device type, operating system, and unique device identifiers, ensuring a tailored experience.</p>
                                </div>
                                <div className="privacy-item" id="secu">
                                    <h3 className="title">How We Use Your Information</h3>
                                    <p>Transaction Processing: Your information is crucial for facilitating transactions, verifying identities, processing payments, and providing you with a comprehensive transaction history.

                                        Communication: Mantos utilizes your contact information responsibly, sending transaction confirmations, important updates, and relevant notices to keep you informed.

                                        User Experience Enhancement: We analyze user data to continually improve Mantos, focusing on refining our app's functionality, user interface, and overall user experience.</p>
                                </div>
                                <div className="privacy-item" id="datasecurity">
                                    <h3 className="title">Data Security</h3>
                                    <p>At Mantos, the security of your information is paramount. We employ industry-standard measures to safeguard your data against unauthorized access, disclosure, or alteration, ensuring your peace of mind.</p>
                                </div>
                                <div className="privacy-item" id="info">
                                    <h3 className="title">Information Sharing</h3>
                                    <p>We do not sell or rent your personal information to third parties. We may share information with:

                                        Service providers and partners necessary for app functionality
                                        Regulatory authorities and law enforcement agencies as required by law
                                        With your consent</p>
                                </div>
                                <div className="privacy-item" id="cooki">
                                    <h3 className="title">Security</h3>
                                    <p>We employ reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
                                </div>
                                <div className="privacy-item" id="ipaddr">
                                    <h3 className="title">Updates to this Privacy Policy</h3>
                                    <p>We may update this Privacy Policy from time to time. The latest version will be posted on our website and within the app.</p>
                                </div>
                                <div className="privacy-item" id="thirdparty">
                                    <h3 className="title">Third-Party Sharing</h3>
                                    <p>We want you to trust us. Rest assured, Mantos does not sell, trade, or transfer your personal information to third parties without your explicit consent, except when required for transaction processing or mandated by law.</p>
                                </div>
                                <div className="privacy-item" id="yourchoices">
                                    <h3 className="title">Your Choices</h3>
                                    <p>Empowering you with control, Mantos respects your choices. You have the right to review, update, and delete your personal information. Moreover, you can choose to opt-out of promotional communications at any time.</p>
                                </div>
                                <div className="privacy-item" id="contactus">
                                    <h3 className="title">Contact Us</h3>
                                    <p>Your feedback is valuable. If you have any questions or concerns about our Privacy Policy, please don't hesitate to contact us at [Your Contact Information]. We are here to ensure your privacy and trust in Mantos.</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Privacypolicy