import React from 'react'
import Img1 from '../../assets/css/img/page-header.png'
import Img2 from '../../assets/images/about/about.png'
import Img3 from '../../assets/images/counter/counter1.png'
import Img4 from '../../assets/images/counter/counter2.png'
import Img5 from '../../assets/images/counter/counter3.png'
import Img9 from '../../assets/images/feature/experience.png'
import Img12 from '../../assets/images/client/client05.png'
import Img13 from '../../assets/images/sponsor/sponsor1.png'
import Img14 from '../../assets/images/sponsor/sponsor2.png'
import Img15 from '../../assets/images/sponsor/sponsor3.png'
import Img16 from '../../assets/images/sponsor/sponsor4.png'
import Img17 from '../../assets/images/sponsor/sponsor5.png'
import Img18 from '../../assets/images/sponsor/sponsor6.png'
import Img19 from '../../assets/images/sponsor/sponsor7.png'
import Carousel from 'react-bootstrap/Carousel';
import {AiFillStar} from 'react-icons/ai'
import {BiPaperPlane} from 'react-icons/bi'

import Img20 from '../../assets/images/client/client7.jpg'
import Img21 from '../../assets/images/client/client02.png'
import Img22 from '../../assets/images/client/client06.jpg'
const Aboutpage = () => {
  return (
    <div>
    <section class="page-header bg_img contactbg" >
        <div class="bottom-shape d-none d-md-block">
            <img src={Img1} alt="css"/>
        </div>
        <div class="container">
            <div class="page-header-content cl-white">
                <h2 class="title">About Us</h2>
           
            </div>
        </div>
    </section>



    <section class="about-section padding-top padding-bottom oh">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-5">
                    <div class="about-thumb rtl pr-xl-15">
                        <img src={Img2} alt="about"/>
                    </div>
                </div>
                <div class="col-xl-7 pl-xl-0">
                    <div class="about-content">
                        <div class="section-header left-style">
                            <h5 class="cate">A Few Words About Us</h5>
                            <h2 class="title">We’re empowering teams to achieve greatness together.</h2>
                            <p>We are a passionate team of Web & App developers, marketers, and 
                                designers focused on driving results for our clients in the fast-growing.
                                We are making products loved by users all over the world. </p>
                        </div>
                        <div class="counter-area-5">
                            <div class="counter-item-5">
                                <div class="counter-thumb">
                                    <img src={Img3} alt="counter"/>
                                </div>
                                <div class="counter-content">
                                    {/* <h3 class="title"><span class="counter">45</span><span>k</span></h3> */}
                                    <p>Users</p>
                                </div>
                            </div>
                            <div class="counter-item-5">
                                <div class="counter-thumb">
                                    <img src={Img4} alt="counter"/>
                                </div>
                                <div class="counter-content">
                                    {/* <h3 class="title"><span class="counter">90</span><span>+</span></h3> */}
                                    <p>Countries</p>
                                </div>
                            </div>
                            <div class="counter-item-5">
                                <div class="counter-thumb">
                                    <img src={Img5} alt="counter"/>
                                </div>
                                <div class="counter-content">
                                    {/* <h3 class="title"><span class="counter">95</span><span>%</span></h3> */}
                                    <p>Satisfaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




{/* 
    <section class="coverage-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="p-0">
                        <h5 class="cate my-3">Our stats say more than any words</h5>
                        <h2 class="title my-3">Apps Without Borders</h2>
                        <p>
                            Mantos app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                        </p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class=" text-lg-right">
                        <div class="rating-area">
                            <div class="ratings">
                                <AiFillStar style={{color:'orange'}}/>
                                <AiFillStar style={{color:'orange'}}/>
                                <AiFillStar style={{color:'orange'}}/>
                                <AiFillStar style={{color:'orange'}}/>
                                <AiFillStar style={{color:'orange'}}/>
                            </div>
                            <span class="average">5.0 / 5.0</span>
                        </div>
                        <h2 class="amount my-2">312,921+</h2>
                        <a href="#0">Total User Reviews <BiPaperPlane/></a>
                    </div>
                </div>
            </div>
            <div class="coverage-wrapper bg_img bg3">
                <div class="border-item-1">
                    <span class="name">North America</span>
                    <h2 class="title">70.7%</h2>
                </div>
                <div class="border-item-2">
                    <span class="name">Asia</span>
                    <h2 class="title">14.4%</h2>
                </div>
                <div class="border-item-3">
                    <span class="name">North Europe</span>
                    <h2 class="title">8.4%</h2>
                </div>
                <div class="border-item-4">
                    <span class="name">South America</span>
                    <h2 class="title">1.8%</h2>
                </div>
                <div class="border-item-5">
                    <span class="name">Africa</span>
                    <h2 class="title">1.8%</h2>
                </div>
                <div class="border-item-6">
                    <span class="name">Australia</span>
                    <h2 class="title">3%</h2>
                </div>
            </div>
        </div>
    </section> */}





    <section class="oh creativity-section padding-bottom bg-max-lg-dark bg_img top_center bg5">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-6 padding-top">
                    <div class="section-header left-style mb-0">
                        <h5 class="cate">We Update Apps for Creativity & Productivity</h5>
                        <h2 class="title">We Strive to Make updation on Mantos App</h2>
                        <p>We are constantly working to improve usability based on data and listen to your voice to find the best results. All of Mantos products are designed with the concerns of developers, 
                        designers, and marketers alike, regardless of their position.A culture that seeks directions and approaches issues from a 
                        diverse perspective, rather than actively insisting on one 
                        solution. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-4 d-none d-lg-block">
                    <img src={Img9} alt="feature"/>
                </div>
            </div>
        </div>
    </section>


     


     {/* <section class="testimonial-section padding-top-2 padding-bottom-2">
        <div class="container">
            <div class="section-header">
                <h5 class="cate">Testimonials</h5>
                <h2 class="title">5000+ happy clients all around the world</h2>
            </div>
            <div class="testimonial-wrapper">
                <div class="testimonial-area testimonial-slider">
                    
                    <Carousel indicators='false'>
      <Carousel.Item>
        <div class="testimonial-item">
                        <div class="testimonial-thumb">
                            <div class="thumb">
                                <img src={Img12} alt="client"/>
                            </div>
                        </div>
                        <div class="testimonial-content">
                            <div class="ratings">
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                            </div>
                            <p>
                            More faster than CashApp and other Apps. Really easy to use, more functions, good design and I don't find any hurdle while using this App.                            </p>
                        </div>
                    </div>
        

      </Carousel.Item>
      <Carousel.Item>
        <div class="testimonial-item">
                        <div class="testimonial-thumb">
                            <div class="thumb">
                                <img src={Img20} alt="client"/>
                            </div>
                        </div>
                        <div class="testimonial-content">
                            <div class="ratings">
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                            </div>
                            <p>
                            Easy to use, works great!!! It is best app for everyone, haven't had any issues with crashes or app not loading.                                    
                            </p>
                        </div>
                    </div>
        

      </Carousel.Item>
      <Carousel.Item>
        <div class="testimonial-item">
                        <div class="testimonial-thumb">
                            <div class="thumb">
                                <img src={Img21} alt="client"/>
                            </div>
                        </div>
                        <div class="testimonial-content">
                            <div class="ratings">
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                            </div>
                            <p>
                            This is a fantastic Mantos App for sending money from one person to another. I really appreciate the function that allows you to seek money from a person in the same way that an invoice would.                                    
                            </p>
                        </div>
                    </div>
        

      </Carousel.Item>
      <Carousel.Item>
        <div class="testimonial-item">
                        <div class="testimonial-thumb">
                            <div class="thumb">
                                <img src={Img22} alt="client"/>
                            </div>
                        </div>
                        <div class="testimonial-content">
                            <div class="ratings">
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                                <span><AiFillStar/></span>
                            </div>
                            <p>
                            I like the ease in sending and receiving money, and the instant transfer fee isn't that high.
                            </p>
                        </div>
                    </div>
        

      </Carousel.Item>
      
    </Carousel> 
                </div>
               
            </div>
        </div>
    </section> */}



    <section class="sponsor-section padding-top-2 padding-bottom">
        <div class="container">
            <div class="section-header mw-100">
                {/* <h5 class="cate">Used by over 1,000,000 people worldwide</h5> */}
                <h2 class="title">Companies that trust us</h2>
            </div>
            <div class="sponsor-slider-4 d-flex">
                <div class="sponsor-thumb">
                    <img src={Img13} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img14} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img15} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img16} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img17} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img18} alt="sponsor"/>
                </div>
                <div class="sponsor-thumb">
                    <img src={Img19} alt="sponsor"/>
                </div>
            </div>
        </div>
    </section>


    </div>
  )
}

export default Aboutpage