import React from 'react'
import { Link } from 'react-router-dom'

const Resetpassword = () => {
  return (
    <div>
          <div class="account-section bg_img bg10 py-5">
        <div class="container mt-5 py-5">
            <div class="account-wrapper top-gap mb-4">
                <div class="account-body">
                    <div class="form-head">
                        <h4 class="title mb-20">Reset Your Passsword</h4>
                        <p>Please enter your email address below.We'll send you instructions to reset your password</p>
                    </div>
                    <form class="account-form">
                        <div class="form-group">
                            <label for="sign-up">Your Email </label>
                            <input type="text" placeholder="Enter Your Email " id="sign-up"/>
                        </div>
                        <div class="form-group text-center">
                            <button type="submit" class="mt-40 mb-2 btn-danger">Send instructions </button>
                        </div>
                    </form>
                    <span class="d-block mt-15">Remember your password? <Link to="/Signin"> Log In</Link></span>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Resetpassword